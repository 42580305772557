import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/SignUpPage.css';
import ReactGA from 'react-ga4';

const SignUpPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: 'user',
    subscribe: false
  });

  const [verificationCode, setVerificationCode] = useState('');
  const [showVerification, setShowVerification] = useState(false); // To show the verification input field
  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [apiError, setApiError] = useState('');
  const [resendTimer, setResendTimer] = useState(0); // Timer for the resend button
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked Sign Up Button',
      label: 'Signup Page',
    });
    
    let timer;
    if (resendTimer > 0) {
      timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [resendTimer]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  // Validate form data
  const validateForm = () => {
    let errors = {};

    if (!formData.firstName.trim()) errors.firstName = 'First Name is required';
    if (!formData.lastName.trim()) errors.lastName = 'Last Name is required';
    if (!formData.email.trim()) errors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) errors.email = 'Email is invalid';
    if (!formData.password) errors.password = 'Password is required';
    else if (formData.password.length < 8) errors.password = 'Password must be at least 8 characters long';

    return errors;
  };

  // Handle form submission for signup
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        // API call to signup
        await axios.post(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/users/signup`, {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          password: formData.password,
          role: formData.role,
          isSubscribed: formData.subscribe
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        // Show verification code input
        setShowVerification(true);
        setSuccessMessage('A verification code has been sent to your email.');
        setResendTimer(60); // Start the 30-second timer for the resend button

      } catch (error) {
        setApiError('Sign-up failed. ' + (error.response?.data?.message || 'Please try again later.'));
      }
    }
  };

  // Handle verification code submission
  const handleVerifyCode = async () => {
    try {
      // API call to verify the code with email and code as query parameters
      const response = await axios.post(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/users/verify`, null, {
        params: {
          email: formData.email,
          code: verificationCode
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });

      // If verification is successful
      if (response.data === 'Account verified successfully.') {
        setSuccessMessage('Email verified successfully! Redirecting...');
        setTimeout(() => navigate('/sign-in'), 2000); // Redirect to lessons page
      } else {
        setApiError('Invalid verification code. Please try again.');
      }

    } catch (error) {
      setApiError('Verification failed. ' + (error.response?.data?.message || 'Please try again later.'));
    }
  };

  // Handle resend verification code
  const handleResendCode = async () => {
    try {
      // API call to verify the code with email and code as query parameters
      const response = await axios.post(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/users/resend-verification`, null, {
        params: {
          email: formData.email,
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });

      // If registration is successful
      if (response.data === 'Verification code resent successfully.') {
        setSuccessMessage('A new verification code has been sent to your email.');
        setResendTimer(60); // Reset the timer for 30 seconds
      } else {
        setApiError('Error in resending verification code. Please try again.');
      }

    } catch (error) {
      setApiError('Failed to resend verification code. ' + (error.response?.data?.message || 'Please try again later.'));
    }
  };

  return (
    <div className="sign-up-container">
        {!showVerification ? (<h2>Create Your Account</h2>) : (<h2>Verify Your Email</h2>)}

        {/* API Error message */}
        {apiError && <p className="error">{apiError}</p>}

        {/* Success Message */}
        {successMessage && <p className="success">{successMessage}</p>}

        {!showVerification ? (
          <form onSubmit={handleSubmit}>
            {/* First Name */}
            <div className="input-group">
              <label>First Name</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
              {formErrors.firstName && <p className="error">{formErrors.firstName}</p>}
            </div>

            {/* Last Name */}
            <div className="input-group">
              <label>Last Name</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
              {formErrors.lastName && <p className="error">{formErrors.lastName}</p>}
            </div>

            {/* Email */}
            <div className="input-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {formErrors.email && <p className="error">{formErrors.email}</p>}
            </div>

            {/* Password */}
            <div className="input-group">
              <label>Password</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              {formErrors.password && <p className="error">{formErrors.password}</p>}
            </div>

            {/* Subscribe to Newsletter */}
            <div className="input-group newsletter-checkbox">
              <input
                type="checkbox"
                name="subscribe"
                checked={formData.subscribe}
                onChange={handleChange}
              />
              <label>
                Subscribe to our newsletter to receive updates.
              </label>
            </div>

            {/* Submit Button */}
            <button type="submit" className="create-account-button">Create Account</button>
            <a href="/sign-in">I already have an account!</a>
          </form>
          
        ) : (
          <div>
            <p>Please enter it below:</p>
            <input
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              maxLength="6"
              placeholder="Enter 6-digit code"
              className="verify-code-text"
            />
            <button onClick={handleVerifyCode} className="verify-code-button">Verify Code</button>
            
            {/* Resend verification code button */}
            <button 
              onClick={handleResendCode} 
              className="resend-code-button" 
              disabled={resendTimer > 0}
            >
              {resendTimer > 0 ? `Resend Code in ${resendTimer}s` : 'Resend Code'}
            </button>
          </div>
        )}
        
    </div>
  );
};

export default SignUpPage;
