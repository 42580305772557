import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const BlogDetailPage = () => {
  const { urlTitle } = useParams(); // Get the blog urlTitle from URL parameters
  const [blog, setBlog] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {

    if (!urlTitle) {
      console.error("urlTitle is undefined");
      setError("Blog URL is invalid.");
      return;
    }

    const fetchBlog = async () => {
      try {
        // Check if we are in review mode or content mode
        let response;
        if (window.location.pathname.includes('/review/')) {
          // Fetch blog in review mode
          response = await axios.get(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/blogs/detail/${urlTitle}/review`);
        } else {
          // Fetch only approved blog
          response = await axios.get(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/blogs/detail/${urlTitle}`);
        }

        if (response.data) {
            setBlog(response.data);
          } else {
            setError("This blog is currently unavailable. It may still be awaiting approval or hasn't been created yet.");
          }
       
      } catch (err) {
        setError('Error fetching blog');
      }
    };

    fetchBlog();
  }, [urlTitle]);

  if (error) {
    return <p>{error}</p>;
  }

  if (!blog) {
    return <p>Loading...</p>;
  }

  return (
    <div className="blogs-page-container">
      <section className="blogs-content">
        <h1>{blog.title}</h1>
        <p>Author: {blog.author}</p>
        <p>Date: {new Date(blog.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
        <div dangerouslySetInnerHTML={{ __html: blog.content }}></div>
        <a style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)' }} href="/blogs">Back To Blogs</a>
      </section>
      
    </div>
  );
};

export default BlogDetailPage;
