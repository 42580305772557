import React, { useEffect } from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';
import { trackOutboundLinkClick } from './analytics';
import '../styles/Footer.css'; 
import ReactGA from 'react-ga4';

const Footer = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Footer Section',
      label: 'Footer Section',
    });
  }, []);
  return (
    <FooterContainer>
      <FooterSections>
        <LogoSocials>
          <a href="/" className="logo">
            <img src="/images/logo-icon-footer.avif" alt="Logo" className="logo-image" />
          </a>
          <p>Elevating Code with AI-Powered Insights</p>
        </LogoSocials>
        <Community>
          <p>Community</p>
          <a href="https://discord.gg/SHKbbWac" target="_blank" className="social-link discord" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://discord.gg/SHKbbWac')}>
              Discord 
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Interface / External_Link"> <path id="Vector" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
          </a>
          <a href="https://www.linkedin.com/company/code-and-beyond/" target="_blank" className="social-link linkedin" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://www.linkedin.com/company/code-and-beyond/')}>
              LinkedIn 
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Interface / External_Link"> <path id="Vector" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
          </a>
          <a href="https://www.youtube.com/@CodeandGoBeyond/" target="_blank" className="social-link youtube" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://www.youtube.com/@CodeandGoBeyond/')}>
              Youtube 
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Interface / External_Link"> <path id="Vector" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
          </a>
        </Community>
        <WritingsLearnings>
          <p>Writings and Learnings</p>
          <a href="/lessons">Lessons</a>
          <a href="/blogs">Blogs</a>
          <a href="/resources">Resources</a>
          <a href="/write">Write a Blog</a>
        </WritingsLearnings>
        <LegalContact>
          <p>More</p>
          <a href="/about-me">Know Your Mentor</a>
          <a href="/contact-us">Contact Us</a>
          <a href="/privacy-policy.html">Privacy Policy</a>
        </LegalContact>
      </FooterSections>
      <hr className="separator-line" />
      <FooterText>Copyright &copy; 2024 CodeAndBeyond.</FooterText>
    </FooterContainer> 
  );
};

const FooterContainer = styled.footer`
  background-color: ${theme.colors.secondaryBackground};
  color: ${theme.colors.text};
  text-align: center;
  padding: 20px;
  margin-top: 30px;
`;

const FooterText = styled.p`
  margin: 0;
  font-family: ${theme.fonts.secondary};
  font-size: ${theme.typography.bodySmall.fontSize};
  font-weight: ${theme.typography.bodySmall.fontWeight};
  line-height: ${theme.typography.bodySmall.lineHeight};
`;

const FooterSections = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;

  div {
    margin: 0 20px;
  }
`;

const LogoSocials = styled.div`
  p {
    margin-bottom: 10px;
    margin-right: 35px;
    margin-top: 5px
    font-family: ${theme.fonts.secondary};
    font-size: ${theme.typography.bodySmall.fontSize};
    font-weight: ${theme.typography.bodySmall.fontWeight};
    line-height: ${theme.typography.bodySmall.lineHeight};
    text-align: left;
  }
  
  a {
    display: block;
    color: ${theme.colors.secondaryText};
    text-decoration: none;
    margin-bottom: 5px;
    text-align: left;
    &:hover {
      text-decoration: underline;
    }      
  }
`;

const Community = styled.div`
  p {
    margin-bottom: 10px;
    font-family: ${theme.fonts.secondary};
    font-size: ${theme.typography.body.fontSize};
    font-weight: ${theme.typography.body.fontWeight};
    line-height: ${theme.typography.body.lineHeight};
    text-align: left;
  }
  
  a {
    display: block;
    color: ${theme.colors.text};
    text-decoration: none;
    margin-bottom: 5px;
    text-align: left;
    &:hover {
      text-decoration: underline;
      color: #FFBB00;
    }
    font-family: ${theme.fonts.secondary};
    font-size: ${theme.typography.bodySmall.fontSize};
    font-weight: ${theme.typography.bodySmall.fontWeight};
    line-height: ${theme.typography.bodySmall.lineHeight};

    svg {
      width: 15px; /* Size of icons */
      height: 15px;
      transition: transform 0.3s ease; /* Smooth animation on hover */
      margin-left: 2px;
      text-align: left;
    }
  }
`;

const WritingsLearnings = styled.div`
  p {
    margin-bottom: 10px;
    font-family: ${theme.fonts.secondary};
    font-size: ${theme.typography.body.fontSize};
    font-weight: ${theme.typography.body.fontWeight};
    line-height: ${theme.typography.body.lineHeight};
    text-align: left;
  }
  
  a {
    display: block;
    color: ${theme.colors.text};
    text-decoration: none;
    margin-bottom: 5px;
    text-align: left;
    &:hover {
      text-decoration: underline;
      color: #FFBB00;
    }
    font-family: ${theme.fonts.secondary};
    font-size: ${theme.typography.bodySmall.fontSize};
    font-weight: ${theme.typography.bodySmall.fontWeight};
    line-height: ${theme.typography.bodySmall.lineHeight};
  }
`;

const LegalContact = styled.div`
  p {
    margin-bottom: 10px;
    font-family: ${theme.fonts.secondary};
    font-size: ${theme.typography.body.fontSize};
    font-weight: ${theme.typography.body.fontWeight};
    line-height: ${theme.typography.body.lineHeight};
    text-align: left;
  }
  
  a {
    display: block;
    color: ${theme.colors.text};
    text-decoration: none;
    margin-bottom: 5px;
    text-align: left;
    &:hover {
      text-decoration: underline;
      color: #FFBB00;
    }
    font-family: ${theme.fonts.secondary};
    font-size: ${theme.typography.bodySmall.fontSize};
    font-weight: ${theme.typography.bodySmall.fontWeight};
    line-height: ${theme.typography.bodySmall.lineHeight};
  }
`;


export default Footer;
