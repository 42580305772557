import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

const PrivateRoute = ({ children }) => {
  const { isLoggedIn, loading } = useContext(UserContext);

  // Show a loading spinner or null while determining login state
  if (loading) {
    return <div></div>;  // You can replace this with a proper loading component
  }

  // Once loading is complete, check the isLoggedIn status
  return isLoggedIn ? children : <Navigate to="/sign-in" />;
};

export default PrivateRoute;

