import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/LessonsPage.css';
import ReactGA from 'react-ga4';

const LessonsPage = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked Lessons Button',
      label: 'Lessons Page',
    });
  }, []);
  return (
    <div className="lessons-container">
      <div className="lessons-content">
        <h1>Software Engineering Almanac</h1>
        <p><b style={{ fontStyle: 'italic' }}>Software Engineering Almanac with Bhuvnesh Arya</b> is a free resource dedicated to delivering in-depth lessons on a wide range of software engineering topics. Each lesson covers essential aspects of coding, system design, software architecture, best practices, and the evolving culture of the software industry. </p>
        <ul className="lessons-list">
          <li><Link to="/lessons/lesson2">Lesson 2: System Design Framework (Nov 2, 2024)</Link></li>
          <li><Link to="/lessons/lesson1">Lesson 1: Microservices Design Principles (Oct 5, 2024)</Link></li>
          {/* Add more lessons as needed */}
        </ul>
      </div>
    </div>
  );
};

export default LessonsPage;
