// App.js

import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import BlogsPage from './pages/BlogsPage';
import BlogSubmissionPage from './pages/BlogSubmissionPage';
import AboutMePage from './pages/AboutMePage';
import ContactMePage from './pages/ContactMePage';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import MyAccount from './profile/MyAccount';
import LessonsPage from './pages/LessonsPage';
import Lesson1 from './lessons/Lesson1';
import Lesson2 from './lessons/Lesson2';
import PrivateRoute from './authentication/PrivateRoute';
import AdminRoute from './authentication/AdminRoute';
import ResourcesPage from './pages/ResourcesPage';
import BlogDetailPage from './blogs/BlogDetailPage';
import MyBlogs from './profile/MyBlogs';
import ReviewBlogs from './profile/ReviewBlogs';
import ManageUsers from './profile/ManageUsers';
import { UserContext } from './contexts/UserContext';
import { jwtDecode } from 'jwt-decode';

// Move this function outside the component to avoid dependency warnings
const validateToken = async (token) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/auth/validate-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    const data = await response.json();

    if (response.ok && data) {
      return true;
    }
  } catch (error) {
    console.error("Error validating session: ", error);
  }
  return false;
};

const App = () => {
  const { isLoggedIn, setIsLoggedIn, user, setUser, isUserAdmin } = useContext(UserContext);

  useEffect(() => {
    const token = localStorage.getItem('token');
  
    const isTokenValid = (token) => {
      try {
        const decoded = jwtDecode(token);
        const now = Date.now() / 1000; // in seconds
        return decoded.exp > now; // true if token has not expired
      } catch (error) {
        return false; // invalid token format
      }
    };

    if (token && isTokenValid(token)) {
      // Token is valid; skip backend validation
      setIsLoggedIn(true);
    } else if (token && !isTokenValid(token)) {
      // Token might be close to expiry; validate with backend
      validateToken(token).then(isValid => {
        setIsLoggedIn(isValid);
      });
    } else {
      setIsLoggedIn(false);
    }    
  }, [setIsLoggedIn]);

  return (
    <Router>
      <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} user={user} setUser={setUser} isUserAdmin={isUserAdmin} />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<Navigate to="/" />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/write" element={<PrivateRoute><BlogSubmissionPage /></PrivateRoute>} />
        <Route path="/about-me" element={<AboutMePage />} />
        <Route path="/contact-us" element={<ContactMePage />} />
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/lessons" element={<LessonsPage />} />
        <Route path="/lessons/lesson1" element={<Lesson1 />} />
        <Route path="/lessons/lesson2" element={<Lesson2 />} />
        <Route path="/blogs/author/:urlAuthor" element={<BlogsPage />} /> 
        <Route path="/blogs/tag/:urlCategory" element={<BlogsPage />} />
        <Route path="/blogs/content/:urlTitle" element={<BlogDetailPage />} />
        <Route path="/blogs/review/:urlTitle" element={<BlogDetailPage />} />
        <Route path="/resources" element={<ResourcesPage />} />  
        <Route path="/my-blogs" element={<MyBlogs />} />
        <Route path="/manage-users" element={<AdminRoute><ManageUsers /></AdminRoute>} />
        <Route path="/review-blogs" element={<AdminRoute><ReviewBlogs /></AdminRoute>} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
