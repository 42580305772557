// Header.js

import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import theme from '../styles/theme';
import ReactGA from 'react-ga4';
import '../styles/Header.css';

// Separate UserProfile component wrapped with React.memo
const UserProfile = React.memo(({ user, isUserAdmin, handleSignOut }) => (
  <div className="user-profile-dropdown">
    <span className="user-profile-text">{user.firstName[0]}{user.lastName[0]}</span>
    <img src="/images/avatar-icon.png" alt="Menu Button" className="menu-icon" />
    <div className="dropdown-content">
      <NavItem href="/my-account">My Account</NavItem>
      <NavItem href="/my-blogs">My Blogs</NavItem>
      {isUserAdmin && <NavItem href="/review-blogs">Review Blogs</NavItem>}
      {isUserAdmin && <NavItem href="/manage-users">Manage Users</NavItem>}
      <NavItem onClick={handleSignOut}>Sign Out</NavItem>
    </div>
  </div>
));

const Header = ({ isLoggedIn, setIsLoggedIn, user, setUser, isUserAdmin }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Header Section',
      label: 'Header Section',
    });
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(prev => !prev);
  };

  const handleSignOut = useCallback(() => {
    setIsLoggedIn(false);
    setUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('authChecked');
    navigate('/sign-in');
  }, [navigate, setIsLoggedIn, setUser]);

  return (
    <HeaderContainer>
      <a href="/" className="logo">
        <img src="/images/logo.avif" alt="Logo" className="logo-image" />
      </a>

      <div className="menu-toggle" onClick={toggleMenu}>
        <span className="menu-text">Menu</span>
        <img src="/images/menu-icon.png" alt="Menu Button" className="menu-icon" />
      </div>

      <div className={`nav-container ${isMenuOpen ? 'open' : ''}`}>
        <Nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
          <NavItem href="/">Home</NavItem>
          <NavItem href="/lessons">Lessons</NavItem>
          <NavItem href="/blogs">Blogs</NavItem>
          <NavItem href="/resources">Resources</NavItem>
          <NavItem href="/write">Write</NavItem>
          <DisplayItem> | </DisplayItem>
          {isLoggedIn ? (
            <UserProfile user={user} isUserAdmin={isUserAdmin} handleSignOut={handleSignOut} />
          ) : (
            <NavItem href="/sign-in">Sign In</NavItem>
          )}
        </Nav>
      </div>
    </HeaderContainer>
  );
};

// Styled components
const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: ${theme.colors.primaryBackground};
  height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 auto;
`;

const Nav = styled.nav`
  display: flex;
`;

const NavItem = styled.a`
  color: ${theme.colors.text};
  margin-left: 20px;
  font-family: ${theme.fonts.secondary};
  font-size: ${theme.typography.body.fontSize};
  text-transform: uppercase;
  font-weight: ${theme.typography.body.fontWeight};
  line-height: ${theme.typography.body.lineHeight};

  &:hover {
    color: ${theme.colors.accent};
  }
`;

const DisplayItem = styled.a`
  color: ${theme.colors.text};
  margin-left: 20px;
  font-family: ${theme.fonts.secondary};
  font-size: ${theme.typography.body.fontSize};
  text-transform: uppercase;
  font-weight: ${theme.typography.body.fontWeight};
  line-height: ${theme.typography.body.lineHeight};
`;

export default Header;
