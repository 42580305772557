import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

const AdminRoute = ({ children }) => {
  const { isLoggedIn, isUserAdmin, loading } = useContext(UserContext);

  // Show a loading spinner or null while determining login state
  if (loading) {
    return <div>Page Loading...</div>;  // You can replace this with a proper loading component
  }

  // Once loading is complete, check the isLoggedIn status
  if(isLoggedIn){
     if(isUserAdmin){
        return children;
     }else{
        return <Navigate to="/lessons" />;
     }
  }else{
    return <Navigate to="/sign-in" />;
  }
};

export default AdminRoute;