import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/ManageUsers.css';

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/users/list`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setUsers(response.data);
      } catch (err) {
        setError('Failed to load users. Please try again.');
      }
    };

    fetchUsers();
  }, []);

  return (
    <div className="manage-users-container">
      <h2>Manage Users</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {users.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }}>Email</th>
              <th style={{ textAlign: 'left' }}>First Name</th>
              <th style={{ textAlign: 'left' }}>Last Name</th>
              <th style={{ textAlign: 'left' }}>Username</th>
              <th style={{ textAlign: 'left' }}>Verification</th>
              <th style={{ textAlign: 'left' }}>Subscription</th>
              <th style={{ textAlign: 'left' }}>Date Joined</th>
              <th style={{ textAlign: 'left' }}>Role</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.email}</td>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.username}</td>
                <td>{"" + user.verified}</td>
                <td>{"" + user.isSubscribed}</td>
                <td>{new Date(user.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                <td>{user.role}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No users found.</p>
      )}
    </div>
  );
};

export default ManageUsers;
