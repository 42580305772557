import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/ReviewBlogs.css';

const ReviewBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  // Fetch all blogs in pending state on component mount
  useEffect(() => {
    const fetchPendingBlogs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/blogs/pending`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setError('');
        setBlogs(response.data);
      } catch (err) {
        setError('Failed to load pending blogs. Please try again later.');
      }
    };

    fetchPendingBlogs();
  }, []);

  // Handle blog approval
  const handleApprove = async (blogId) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_USER_SERVICE_API_URL}/api/blogs/${blogId}/status`,
        { status: 'approved' },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      setSuccessMessage('Blog approved successfully.');
      setBlogs(blogs.filter((blog) => blog.id !== blogId));
    } catch (error) {
      setError('Failed to approve blog. Please try again.');
    }
  };

  // Handle blog rejection
  const handleReject = async (blogId) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_USER_SERVICE_API_URL}/api/blogs/${blogId}/status`,
        { status: 'rejected' },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      setSuccessMessage('Blog rejected successfully.');
      setBlogs(blogs.filter((blog) => blog.id !== blogId));
    } catch (error) {
      setError('Failed to reject blog. Please try again.');
    }
  };

  return (
    <div className="review-blogs-container">
      <h2>Review Pending Blogs</h2>
      {error && <p className="error-message">{error}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
      {blogs.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }}>Title</th>
              <th style={{ textAlign: 'left' }}>Date Submitted</th>
              <th style={{ textAlign: 'left' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {blogs.map((blog) => (
              <tr key={blog.id}>
                <td>
                  <a href={`/blogs/review/${blog.urlTitle}`} style={{ textDecoration: 'underline', cursor: 'pointer', color: 'rgb(0, 84, 147)' }}>
                    {blog.title}
                  </a>
                </td>
                <td>{new Date(blog.createdAt).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</td>
                <td>
                <div style={{ display: 'flex', gap: '10px' }}>  
                    <button onClick={() => handleApprove(blog.id)} className="approve-button">Approve</button>
                    <button onClick={() => handleReject(blog.id)} className="reject-button">Reject</button>
                </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No pending blogs to review.</p>
      )}
    </div>
  );
};

export default ReviewBlogs;
