import React, { useEffect } from 'react';
import '../styles/Introduction.css';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Resources = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Resources Section',
      label: 'Resources Section',
    });
  }, []);

  return (
    <div className="intro-container">
      <div className="intro-content">
        <div className="intro-image">
          <a href="/resources"><img src="/images/resources.avif" alt="Resources" /></a>
        </div>
        <div className="home-container">
          <p>
            The <Link style={{color: '#0069d2'}} to="/resources"><b>Resources</b></Link> page offers a carefully selected collection of links to the best external content on topics like 
            software architecture, system design, software engineering, big data engineering, AI and more. Each section provides handpicked 
            articles and research papers, helping you stay updated and enhance your knowledge in critical areas of technology.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Resources;
