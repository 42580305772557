import React, { useEffect, useRef, useState } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css'; // Import the Quill CSS file

const BlogEditor = ({ onChange }) => {
  const editorRef = useRef(null); // Ref to hold the editor DOM node
  const quillRef = useRef(null);  // Ref to hold the Quill instance

  const [content, setContent] = useState(""); // For blog content
  
  const maxContentLength = 20000;

  useEffect(() => {
    // Prevent initializing Quill multiple times
    if (!quillRef.current) {
      const quill = new Quill(editorRef.current, {
        theme: 'snow', // Use the 'snow' theme
        placeholder: 'Write your blog here...',
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            ['link', 'image'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean'],
          ],
        },
      });

      quillRef.current = quill; // Save the instance to the ref

      // Listen for text-change events to update parent component
      quill.on('text-change', () => {
        const editorContent = quill.root.innerHTML;
        if (editorContent.length <= maxContentLength) {
          setContent(editorContent); // Update content state
          onChange(editorContent); // Pass the content to parent component
        }
      });
    }
  }, [onChange]); // Only run once when the component mounts

  return (
    <div>
      {/* Blog content editor with character counter */}
      <div>
        <label>
          Blog Content (Characters Written: {content.length}/{maxContentLength}):
        </label>
        <div ref={editorRef} style={{ height: '300px', marginBottom: '10px' }} /> {/* Editor container */}
      </div>
    </div>
  );
};

export default BlogEditor;
