import React, { useEffect } from 'react';
import '../styles/Introduction.css'; // Create a CSS file for styling
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Blogs = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Blogs Section',
      label: 'Blogs Section',
    });
  }, []);
  return (
    <div className="intro-container">
      <div className="intro-content">
      <div className="intro-image">
            <a href="/blogs"><img src="/images/blogs.avif" alt="Blogs" href="/blogs" /></a>
        </div>
      <div className="home-container">
      <p>The <Link style={{color: '#0069d2'}} to="/blogs"><b>Blogs</b></Link> page is a curated collection of valuable articles crafted by the developer community, focusing on software engineering, data engineering, system design, and the latest technologies. Each post offers insights from experienced developers, delving into industry best practices and providing practical examples that enhance your understanding and skills. </p> 
    </div>
      </div>
      
    </div>
  );
}

export default Blogs;