import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);  // New isUserAdmin state
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // On initial load, check if the user info is saved in localStorage
    const storedUser = localStorage.getItem("user");
    const storedToken = localStorage.getItem("token");
    
    if (storedUser && storedToken) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      setIsLoggedIn(true);
      setLoading(false);

      // Check if user is an admin based on the role field
      if (parsedUser.role === "admin") {
        setIsUserAdmin(true);
      }
    }

    // Set loading to false once the check is done
    setLoading(false);
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, isLoggedIn, setIsLoggedIn, isUserAdmin, loading }}>
      {children}
    </UserContext.Provider>
  );
};
