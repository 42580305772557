import React, { useEffect } from 'react';
import '../styles/Introduction.css'; // Create a CSS file for styling
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Lessons = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked Lessons Button',
      label: 'Lessons Page',
    });
  }, []);
  return (
    <div className="intro-container">
      <div className="intro-content">
      <div className="intro-image">
            <a href="/lessons"><img src="/images/lessons.avif" alt="Lessons" href="/lessons" /></a>
        </div>
      <div className="home-container">
      <p><Link style={{color: '#0069d2'}} to="/lessons"><b>Software Engineering Almanac</b></Link>  offers insightful lessons on coding, system design, software architecture, best practices, and software industry culture. Each lesson is designed to help you advance in your software engineering journey with practical tips and techniques. Explore new lessons regularly and deepen your knowledge in this ever-evolving field.</p> 
    </div>
      </div>
      
    </div>
  );
}

export default Lessons;
