import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/SignInPage.css';
import ReactGA from 'react-ga4';
import { UserContext } from '../contexts/UserContext';
//import { signInWithGoogle } from '../api/auth';

const SignInPage = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [apiError, setApiError] = useState('');
  const [showPassword, setShowPassword] = useState(false); // New state for password visibility
  const { setUser, setIsLoggedIn  } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked Sign In Button',
      label: 'SignIn Page',
    });

    const storedToken = localStorage.getItem('token');
    
    if (storedToken) {
      navigate("/lessons");
    }
  }, [navigate]);

  /*const handleGoogleSignIn = async () => {
    try {
      const response = await signInWithGoogle();
      localStorage.setItem('token', response.token);
      setUser(response.user);
      setIsLoggedIn(true);
      navigate("/blogs");
    } catch (error) {
      console.error("Error during Google Sign-In: ", error);
    }
  };*/
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSignIn = async (formData) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/auth/signin`, formData);
      const responseData = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
      if (responseData.token) {
        localStorage.setItem('token', responseData.token);
        localStorage.setItem('user', JSON.stringify(responseData));
        localStorage.setItem('authChecked', true);
        setUser(responseData);
        setIsLoggedIn(true);
        navigate('/lessons');
      } else {
        console.error("No token found in response.");
      }
    } catch (error) {
      setApiError('Sign-in failed. ' + (error.response?.data?.message || 'Please check your credentials and try again.'));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      email: event.target.email.value,
      password: event.target.password.value
    };
    handleSignIn(formData);
  };

  return (
    <div className="sign-in-container">
      <h2>Welcome Back!</h2>
      <p1>To get started, sign in to your account.</p1>
      {apiError && <p className="error">{apiError}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Enter your email"
          required
        />
        <div className="password-container">
          <input
            type={showPassword ? "text" : "password"} // Toggle type based on showPassword
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password"
            required
          />
          <span
            className="toggle-password"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? "👁️" : "👁️‍🗨️"}
          </span>
        </div>

        <div className="form-footer">
          <a href="/reset-password" className="forgot-password-link">Forgot Password</a>
        </div>

        <button type="submit" className="email-sign-in-button">Sign in with Email</button>
      </form>
      <a href="/sign-up">Create a new account</a>
    </div>
  );
};

export default SignInPage;
