import React, { useEffect } from 'react';
import '../styles/Introduction.css'; // Create a CSS file for styling
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Introduction = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Introduction Section',
      label: 'Introduction Section',
    });
  }, []);
  return (
    <div className="intro-container">
      <div className="intro-content">
        <div className="intro-image">
            <img src="/images/bhuvnesh.avif" alt="Bhuvnesh Arya" />
        </div>
        <h1>Empowering Developers with AI-Driven Code Insights</h1>
        <p1>
          “Transitioning from college to a professional career or from a junior developer to a seasoned engineer can be a challenging journey. CodeAndBeyond aims to support you through this process by leveraging AI technology to enhance your skills and provide valuable feedback on your code. Our goal is to offer resources and tools that help you refine your abilities and gain actionable insights into your codebase.”
        </p1>
        <p>
          <Link style={{color: '#0069d2'}} to="/about-me"><b>Bhuvnesh Arya</b></Link>, Software Architect
        </p>
        <p>
          This website is created to help fresh graduates and developers elevate their skills through advanced AI-powered technology. Here, you'll find tools for code review, skill-enhancing resources, and valuable insights to guide you on your path to becoming a more proficient and confident developer.
        </p>
      </div>
      
    </div>
  );
}

export default Introduction;
