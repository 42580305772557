import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { UserContext } from '../contexts/UserContext';
import '../styles/MyAccount.css';

// Move this function outside the component to avoid dependency warnings
const userDetails = async (token) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/users/detail`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    const data = await response.json();

    if (response.ok && data) {
      return data;
    }
  } catch (error) {
    console.error("Error fetching user details: ", error);
  }
};


const MyAccount = () => {
  const { user, setUser } = useContext(UserContext);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    company: '',
    professionalTitle: '',
    email: ''  // non-editable
  });
  const [apiError, setApiError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (user && token) {
      // Pre-fill the form with user data
      userDetails(token).then(data => {
        setFormData({
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          company: data.company || '',
          professionalTitle: data.professionalTitle || '',
          email: data.email || '',  // email is non-editable
          username: data.username || ''
        });
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    setSuccessMessage('');
    setApiError('');
    e.preventDefault();
    try {
      const response = await axios.put(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/users/update`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      // Update context and show success message
      setUser(response.data);
      setSuccessMessage('Your details have been updated successfully!');
      setApiError('');  // Clear any previous errors
    } catch (error) {
      // Capture error message from response if available
      const errorMessage = error.response?.data || 'An unexpected error occurred.';
      setSuccessMessage('');
      setApiError(`Error updating user details: ${errorMessage}`);  // Set the error message
      console.error('Error updating user details:', errorMessage);  // Log the error for debugging
    }
  };

  return (
    <div className="my-account-page-container">
      <h2>My Account</h2>
      {successMessage && <p className="success-message">{successMessage}</p>}
      {apiError && <p className="error-message">{apiError}</p>}
      
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            readOnly
          />
        </div>

        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="company">Company</label>
          <input
            type="text"
            id="company"
            name="company"
            value={formData.company}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="professionalTitle">Professional Title</label>
          <input
            type="text"
            id="professionalTitle"
            name="professionalTitle"
            value={formData.professionalTitle}
            onChange={handleChange}
          />
        </div>

        <button className="update-account-button" type="submit">Save Changes</button>
      </form>
    </div>
  );
};

export default MyAccount;
