import React, { useState, useContext } from 'react';
import BlogEditor from '../components/BlogEditor';
import { UserContext } from '../contexts/UserContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/BlogSubmissionPage.css';

const BlogSubmissionPage = () => {
  const { user } = useContext(UserContext);
  const [content, setContent] = useState('');
  const [title, setTitle] = useState(""); // For blog title
  const [category, setCategory] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage] = useState(''); // Added for user feedback
  const navigate = useNavigate();
  const maxTitleLength = 120;
  
  // Title change handler
  const handleTitleChange = (e) => {
    setErrorMessage('');
    const newTitle = e.target.value;
    if (newTitle.length <= maxTitleLength) {
      setTitle(newTitle);
    }
  };

  const backendUrl = `${process.env.REACT_APP_USER_SERVICE_API_URL}/api`; 

  const convertCategoryToSlug = (category) => {
    return category.toLowerCase().replace(/\s+/g, '-');
  };

  const convertTitleToSlug = (title) => {
    return title.toLowerCase().replace(/\s+/g, '-');
  };
  
  // Handle form submission
  const handleSubmit = async (status) => {
    if (!category) {
      setErrorMessage('Category is required.');
      return;
    }

    if (!title) {
      setErrorMessage('Title is required.');
      return;
    }

    try {
      const categorySlug = convertCategoryToSlug(category);
      const titleSlug = convertTitleToSlug(title);
      const author = `${user.firstName} ${user.lastName}`;

      const response = await axios.post(`${backendUrl}/blogs`, {
        title: title,
        author: author,
        content: content,
        category: category,
        urlTitle: titleSlug,
        urlCategory: categorySlug,
        urlAuthor: user.username,
        status: status,
      }, {
        headers: {
          'Content-Type': 'application/json',
           Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      alert('Your blog has been submitted for review and is currently pending approval. In the meantime, feel free to explore other articles on the site!');
      navigate('/blogs'); // Redirect user to blogs page
      console.log('Blog saved:', response.data);
    } catch (error) {
      setErrorMessage('Error saving blog. Please try again later.');
      console.error('Error saving blog:', error);
    }
  };

  return (
    <div className="blogs-submission-page-container">
      <div className="blogs-submission-page-content">
        <h1>Write a Blog</h1>
        <select
          value={category}
          onChange={(e) => {
            setCategory(e.target.value);
            setErrorMessage('');
          }}
          required
        >
          <option value="">Select a category</option>
          <option value="Software Engineering">Software Engineering</option>
          <option value="Data Engineering">Data Engineering</option>
          <option value="Machine Learning">Machine Learning</option>
          <option value="Cloud Computing">Cloud Computing</option>
          <option value="Artificial Intelligence">Artificial Intelligence</option>
          <option value="DevOps">DevOps</option>
          <option value="Web Development">Web Development</option>
        </select>
        {errorMessage && <p className="blog-error-message">{errorMessage}</p>}
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        {/* Title input with character counter */}
        <div>
          <label>
            Title (Characters Written: {title.length}/{maxTitleLength}):
          </label>
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
            placeholder="Enter blog title"
          />
        </div>
        
        <BlogEditor onChange={setContent} />

        <div className="blog-submission-buttons">
          <button
            onClick={() => handleSubmit('pending')}
            className="submit-approval-button"
          >
            Submit for Approval
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogSubmissionPage;
