import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/MyBlogs.css';

const MyBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null);

  // Fetch user-specific blogs on component mount
  useEffect(() => {
    const fetchUserBlogs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/blogs/my-blogs`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          });
        setError('');  
        setBlogs(response.data);
      } catch (err) {
        setError('Failed to load blogs. Please try again later.');
      }
    };

    fetchUserBlogs();
  }, []);

  return (
    <div className="my-blogs-page-container">
      <h2>My Blogs</h2>
      {error && <p className="error-message">{error}</p>}
      {blogs.length > 0 ? (
       <table>
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>Title</th>
            <th style={{ textAlign: 'left' }}>Date Submitted</th>
            <th style={{ textAlign: 'left' }}>Status</th>
          </tr>
        </thead>
        <tbody>
          {blogs.map((blog) => (
            <tr key={blog.id}>
              <td>
                <a href={`/blogs/content/${blog.urlTitle}`} style={{ textDecoration: 'underline', cursor: 'pointer', color: 'rgb(0, 84, 147)' }}>
                  {blog.title}
                </a>
              </td>
              <td>{new Date(blog.createdAt).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</td>
              <td>{blog.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <p>No blogs found. Start writing to see them here!</p>
            <a
                style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    fontSize: '1rem',
                    color: 'rgb(0, 84, 147)'
                }}
                href="/write"
            >
                Write a Blog
            </a>
        </div>

      )}
    </div>
  );
};

export default MyBlogs;
